/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */


import { Logger } from './logger'

/**
 * @module dispatch
 */

 let __log

/**
 * Adds a requirement to the message that the receiver requires the request to
 * come from a vault root app. These methods are inaccessible to normal user
 * applications.
 *
 * @constructor
 * @param {MessageReceiver} receiver - The target message receiver
 *
 */
export const RootAuthFilter = function (receiver) {
  return function (context, event) {
    if (context.mode === 'root') return receiver(context, event)
  }
}


/**
 * Adds a requirement to the message receiver that requires the request provide
 * an authenticaton token for role.
 *
 * @constructor
 * @param {string} role - The permission role identifier.
 * @param {MessageReceiver} receiver - The target message receiver.
 *
 */
export const TokenAuthFilter = function (role, receiver) {
  return function (context, event) {
    
  }
}


/**
 * Implements a message handling framework for applications to process freeform
 * events and route them to application defined event handlers.
 *
 * @constructor
 * @param {Object} context - Context object to bind receivers to when calling.
 */
export class MessageDispatcher {
  constructor (context, logger) {
    __log = new Logger('MessageDispatcher', logger)

    this.__context = context
    this.__receivers = []
  }

  /**
   * Adds an application event receiver to this dispatcher.
   * @param {MessageReceiver} receiver - Target receiver
   */
  addReceiver (receiver) {
    if (!('dispatchTo' in receiver) || typeof(receiver.dispatchTo) !== 'function') {
      throw 'Receiver does not implement MessageDispatcher interface!'
    }

    this.__receivers.push(receiver)
  }

  /**
   * Removes an application event receiver from this dispatcher.
   * @param {MessageReceiver} receiver - Target receiver
   */
  removeReceiver (receiver) {
    this.__receivers = this.__receivers.filter((v, i, a) => v === receiver)
  }

  /**
   * Dispatch event to appropriate message receiver. First receiver found is
   * the only one called.
   * @param {Event} event - Event to dispatch
   */
   
  dispatch (event) {
     if (event.data && event.data.wm_collect_data_popup) {  
        console.log('got wm_collect_data_popup ', event)
        if (!window.noLocalStorageAccess) {
           return
        }
        console.log('activateKlaatu ', navigator.userActivation ? navigator.userActivation.isActive : 'n/a')
        window.popup = window.open(window.origin + '/popup.html', '_blank', "width=1,height=1,left=1,right=1" )
        return
     }
     
     if (event.data && event.data.wm_save_data_popup) {  
        console.log('got wm_save_data_popup ', event)
        if (!window.noLocalStorageAccess) {
           return
        }
        console.log('activateKlaatu ', navigator.userActivation ? navigator.userActivation.isActive : 'n/a')
        window.popup = window.open(window.origin + '/popup.html#save', '_blank', "width=1,height=1,left=1,right=1" )
        return
     }
     
     
     return this.real_dispatch(event)
  }
  
  async real_dispatch (event) {
    // Ignore empty messages.
    if (!event.data || (typeof event.data != 'object') || event.data === '' || event.data === {}) {
      return
    }

    for (var i = 0; i < this.__receivers.length; i++) {
      const receiver = this.__receivers[i].dispatchTo(this.__context, event)
      if (!receiver) continue

      return receiver.bind(this.__context)(event)
        .then(r => {
          if (r && r.transferables) {
           event.source.postMessage({
             callback: event.data.callback, result: r
            },
            event.origin === 'null' ? '*' : event.origin, r.transferables)
          } else {
           event.source.postMessage({
             callback: event.data.callback, result: r
            },
            event.origin === 'null' ? '*' : event.origin)
          }
        })
        .catch(e => {
          try { // Try to send error object, else stringify.
            event.source.postMessage({
              callback: event.data.callback, error: e
            },
            event.origin)
          } catch (_) {
            event.source.postMessage({
              callback: event.data.callback, error: e.toString()
            },
            event.origin === 'null' ? '*' : event.origin)
          }
        })
    }

    if (!(event.data.sender && event.data.sender.startsWith('postmsg-rpc/client'))) {
      __log.warn("Unrecognised event:", event)
    }
  }
}

