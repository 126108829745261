/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */
import { Logger } from '../logger'

import * as utils from '../utils'

// XXX - Separate into proper interface file.
const { WM_EVENT_PRE_BOOTING, WM_EVENT_BOOTING, WM_EVENT_BOOTED, WM_EVENT_RUNNING } = utils

var __log
/**
 * IPCRouter creates sub iframes and forwards messages
 * for the purpose of enabling secure communication between dapps
 */
export default class IPCRouter {
  /**
   * 
   */
  constructor(context, logger) {
    __log = new Logger("IPC", logger)

    this.__context = context
  }

  /**
   * 
   * @param {*} vault 
   */
  install (vault) {
    this.vault = vault

    vault._ipc_callback_counter = 0
    vault._ipc_callbacks = {}

    vault.addReceiver(this)
  }

  /**
   * 
   * @param {*} event 
   */
  async handleMessage(event) {
    const req = event.data
    const params = req.IPCRouterRequest

    // SEC -- Add invokation permission check

    let receiver = this.wm.tasks[params.target]
    if (!receiver) {
      try {
        __log.info("Requesting service boot of " + params.target)

        await this.wm.bootApp(params.target, { ipc: true}, { })
        receiver = this.wm.tasks[params.target]
      } catch (e) {
        return { ipc_error: e }
      }
    }

    if (receiver.state === WM_EVENT_BOOTING || receiver.state === WM_EVENT_PRE_BOOTING) {
      __log.info("Waiting for service " + params.target + " to start...")
      const wm = this.wm;
      await new Promise(resolve => {
        wm.on(WM_EVENT_BOOTED, function (loc) {
          if (loc !== params.target) return
          wm.off(loc)
          return resolve()
        })
      })
      // update receiver
      receiver = this.wm.tasks[params.target] 
    }

    if (receiver.state !== WM_EVENT_RUNNING) {
      return { ipc_error: "Service failed to start" }
    }
    __log.info("Service " + params.target + " started!")
    let source = this.wm.source2scope.get(event.source)

    try {
      const response = await new Promise(function (resolve, reject) {
        const id = 'callback-' + this._ipc_callback_counter++

        params.payload.callback = id
        params.payload.origin = source

        this._ipc_callbacks[id] = [resolve, reject]

        receiver.iframe.contentWindow.postMessage(params.payload, "*", params.transfer)
      }.bind(this))

      return { ipc_result: response }
    } catch (e) {
      __log.info("Routing error from " + params.target + " to client " + source + ": ", e)
      return { ipc_error: e }
    }
  }

  /**
   * 
   * @param {*} event 
   */
  async handleCallback(event) {
    const { callback: cbId, result, error } = event.data.IPCRouterRequest

    if(cbId === undefined) {
      __log.info("Attempted to process response without callback id:", event)
      return
    }

    if (!(cbId in this._ipc_callbacks)) {
      __log.info("Could not resolve response with id:", cbId)
      return
    }
    if (error) {
       const reject = this._ipc_callbacks[cbId][1]
       delete this._ipc_callbacks[cbId]
       return reject(error)
    } else {
       const resolve = this._ipc_callbacks[cbId][0]
       delete this._ipc_callbacks[cbId]
       return resolve(result)
    }
  }

  /**
   * 
   * @param {*} context 
   * @param {*} event 
   */
  dispatchTo (context, event) {
    const req = event.data

    if('IPCRouterRequest' in req) {
      if(req.IPCRouterRequest.payload === undefined) {
        return this.handleCallback
      } else {
        return this.handleMessage
      }
    }

    return null
  }
}