module.exports = {
  development: {
  },

  testing: {
  },

  release: {
  }
}
