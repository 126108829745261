/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */

 
/**
 * @module logger
 */

/**
 * Implements a stackable logging framework, for complex projects.
 *
 * @constructor
 * @param {string} tag - Module identifier prefix.
 * @param {Logger} logger - Optional parent logger for stacking.
 */
export class Logger {
  constructor (tag, logger) {
    this.__tag = tag
    this.__logger = logger || console
  }

  /** Output info logging message */
  info () {
    let params = Array.prototype.slice.call(arguments)
    if (this.__tag) params.unshift(this.__tag + ':')
    this.__logger.info.apply(this.__logger, params)
  }

  /** Output warn logging message */
  warn () {
    let params = Array.prototype.slice.call(arguments)
    if (this.__tag) params.unshift(this.__tag + ':')
    this.__logger.warn.apply(this.__logger, params)
  }

  /** Output error logging message */
  error () {
    let params = Array.prototype.slice.call(arguments)
    if (this.__tag) params.unshift(this.__tag + ':')
    this.__logger.error.apply(this.__logger, params)
  }
}
