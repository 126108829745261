/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */

import './klaatu.css';
import Vault from './vault.js'
import {sendEvent} from './telemetry';

/**
 * Import Runtime Configuration
 */
var config = require('../zippie.config.js')[runtime_mode]
console.info('VAULT: Runtime Mode:', runtime_mode)

// Store vault URI in config, so applications can request it.
config.uri = window.location.origin


/**
 * Vault Entry-Point
 */
let vault = new Vault(config)

if (runtime_mode === 'development' || runtime_mode === 'testing') {
  window.vault = vault
}
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
vault.startup()
  .then(() => {
    const firstRun = window.localStorage.getItem('firstRun')
    if(!firstRun) {
      //generate random value for monitor purpose  
        const key = uuidv4()
        sendEvent('first-start', {monitor_key: key})
        if (!window.klaatu_embedded_scenario) {
          window.localStorage.setItem('monitor_key', key)
          window.localStorage.setItem('firstRun', true)
        }
    }
  })
