/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */

import VERSION from '../version.js'

import { Logger } from './logger'
import { MessageDispatcher } from './dispatch'

import { hashToParams, detectDeviceName } from './utils'

var __log

// vault database contains:
// cache of app hash -> app-pubex
// device local private key
// device authentication private key
// seed piece 1 out of 2 (2of2) encrypted with device local private key
// forgetme server url if non-standard

//TODO:
//  - Local caching of pubex's

/**
 * Zippie Vault
 *
 *   The Zippie Vault stores and manages a users' digital identity and access to
 * it by 3rd party applications. A users' digital identity from the vaults
 * perspective is a master seed, which is used to derive a tree of encryption
 * and message signing keys for various purposes. As well as what devices are
 * allowed to have access to this master seed by the user to perform
 * authenticated operations.
 *
 *   Each of a users' device has two keys, an authentication key and a local
 * device key. The users' master seed is split into two slices, one of which is
 * stored remotely on a server but encrypted against the devices' local key.
 * The other slice is stored locally, and encrypted against the same device key.
 *
 *   When the vault is required to do an operation that requires the master seed
 * usually for private key deriviation for a cryptographic operation, like
 * ethereum transaction signing. The encrypted remote slice is retrieved from
 * the server, decrypted and combined with the local slice, it is then used and
 * dereferenced as soon as the operation is complete in order to reduce the time
 * that the master seed is stored in memory in it's unencrypted form.
 *
 */
export default class Vault {
  /**
   * Constructor
   */
  constructor (config) {
    // Local storage and configuration
    this._config = config
    this.store = window.localStorage

    // Create logger
    this.logger = new Logger("KLAATU")
    __log = this.logger

    // Create event dispatcher
    this.dispatcher = new MessageDispatcher(this, __log)

    // Install base message processors.
    this.dispatcher.addReceiver(this)
    
    // Vault plugins
    this._plugins = []

    // Vault execution mode (host|enclave)
    this.mode = undefined
    this.params = {}

    // Import plugins
    __log.info("Loading plugins:", KLAATU_PLUGINS_ENABLED)
    const plugins = KLAATU_PLUGINS_ENABLED.map(i =>
      new (require('./plugins/' + i)).default(this, __log)
    )
    this.install(plugins)
  }

  /**
   * Merge config data with that stored in localstorage and return.
   * XXX - Should probably cache it in memory on load.
   */
  get config () {
    let result = JSON.parse(JSON.stringify(this._config))
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)
      if (!key.startsWith('config.')) continue

      let parts = key.split('.').slice(1)
      let parent = result
      for (let i = 0; i < parts.length - 1; i++) {
        parent = parent[parts[i]]
      }
      parent[parts[parts.length-1]] = localStorage.getItem(key)
    }
    return result
  }

  /**
   * Install vault plugin/s
   */
  install (param) {
    if (param.constructor === Array) {
      this._plugins = this._plugins.concat(param)
      return
    }

    this._plugins.push(param)
    return
  }

  /**
   * Run through all registered plugins and execute a hook function if defined.
   */
  async plugin_exec(hook, params) {
    for (let i = 0; i < this._plugins.length; i++) {
      let plugin = this._plugins[i]
      if (typeof plugin[hook] === 'function') {
        await plugin[hook].apply(plugin, [].slice.call(arguments, 1))
      }
    }
  }

  /**
   * Configure vault and registered plugins
   */
  async configure () {
    __log.info('Configuring...')

    // Check to see if we're running in root mode.
    //if (window.top === window.self) {
      __log.info('Running in root mode.')
      this.mode = 'root'

    //} else {
    //  throw 'Klaatu does not run in an iframe'
    //}

    // Parse vault query parameters
    this.params = hashToParams(window.location)
    __log.info('Parsed vault parameters:', this.params)

    // Iterate vault plugins install phase.
    await this.plugin_exec('install', this)

    // Start listening for incoming message events
    self.addEventListener('message', ev => this.dispatcher.dispatch(ev))

    // Iterate vault plugins configure phase.
    await this.plugin_exec('configure')
    this._isConfigured = true
  }

  /**
   * Startup Zippie Vault
   */
  async startup () {
    if (!this._isConfigured) await this.configure()

    __log.info('Starting up...')

    // Iterate vault plugins startup phase and collect promises.
    let promises = []
    await this.plugin_exec('startup', promises)
  }

  /**
   * Return vault version information.
   */
  async getVersion (req) {
    return VERSION
  }

  /**
   * Return vault configuration information
   */
  async getConfig (req) {
    return this.config
  }

  /**
   * 
   */
  async setConfig (ev) {
    let req = ev.data.setConfig

    let parts = req.key.split('.')
    let parent = this._config
    for (var i = 0; i < parts.length - 1; i++) {
      parent = parent[parts[i]]
    }
    parent[parts[parts.length-1]] = req.value

    this.store.setItem('config.' + req.key, req.value)
    return true
  }

  /**
   *
   */
  async reboot (req) {
    window.location.reload()
  }

  /**
   * MessageReceiver Interface
   */
  dispatchTo (context, event) {
    let req = event.data

    if (context.mode === 'root') { // ROOT-MODE ONLY RECEIVERS
      if ('reboot' in req) return this.reboot
      if ('config' in req) return this.getConfig
      if ('setConfig' in req) return this.setConfig
    }

    if ('version' in req) return this.getVersion
    return null
  }

  /**
   * MessageDispatch Interface
   */
  addReceiver (receiver) {
    if (receiver.constructor === Array) {
      for (let i = 0; i < receiver.length; i++) {
        this.dispatcher.addReceiver(receiver[i])
      }
      return
    }

    if (typeof receiver.dispatchTo === 'function') {
      this.dispatcher.addReceiver(receiver)
      return
    }

    throw 'Invalid argument, expecting Array or MessageDispatch interface.'
  }
}
