import axios from 'axios'
import {getKlaatuEnv} from './utils';


const telemetryUris = {
  development: 'https://fms.dev.zippie.com/telemetry',
  testing: 'https://fms.testing.zippie.com/telemetry',
  release: 'https://fms.zippie.com/telemetry'
}

const getTelemetryUrl = () => {
  const mode = getKlaatuEnv()

  return telemetryUris[mode]
}

export const sendEvent = async (type, data) => {
  const telemetryUrl = getTelemetryUrl()
  const payload = {
    type: type,
    data: { timestamp: Math.floor(Date.now() / 1000), host: window.location.host,  useragent: navigator.userAgent, ...data },
  }
  return await axios.post(telemetryUrl, payload, {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  })
}