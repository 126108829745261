/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */
import * as utils from '../utils'
import XMLHttpRequestPromise from 'xhr-promise'
import { WeakMap } from 'cross-domain-safe-weakmap'
import EventEmitter from 'wolfy87-eventemitter'
import IpfsBridgeServer from '@zippie/ipfs-bridge/src/server'
import Crypto from 'crypto'
import {sendEvent} from '../telemetry';
import { Logger } from '../logger'

// Handy constants
const MANIFEST_PATH = "/manifest.json"
const INFURA_API_KEY = 'bd8863c27cdf463f93eaaa498c24d6dc'

// XXX - Separate into proper interface file.
const {WM_EVENT_PRE_BOOTING,  WM_EVENT_BOOTING, WM_EVENT_BOOTED, WM_EVENT_RUNNING  } = utils
const whiteListApps = {development: ['/ens/zippie/mycredentials-dev.zippie', '/ens/zippie/pay-service-dev.zippie', '/ens/zippie/help-center-dev.zippie'], testing: ['/ens/zippie/mycredentials-testing.zippie', '/ens/zippie/pay-service-testing.zippie', '/ens/zippie/help-center-testing.zippie'], release: ['/ens/zippie/mycredentials.zippie', '/ens/zippie/pay-service.zippie', '/ens/zippie/help-center.zippie']}

const runtime_mode = utils.getKlaatuEnv();

let switcherurl = SWITCHER_URI[runtime_mode]
let contenturl 
let ens_cache = {}
let __log



const getHashValue = (key) => {
  var matches = location.hash.match(new RegExp(key+'=([^&]*)'));
  return matches ? matches[1] : null;
}

const removeHashParam = (param) => {
  if(getHashValue(param)) {
    const hash = window.location.hash.substr(1);
    const newHash = hash.split('&').reduce(function (result, item) {
      const parts = item.split('=');
      if(parts[0] == param) {
        return result
      }
      if(result === '') {
        return result + item
      }
      return result + '&' + item;
    }, '');
    window.location.hash = newHash
    return true
  }
}

/**
 * WM handles all running windows/apps underneath Klaatu, incl. headless ones
 */
export default class WM {
  /**
   * 
   * @param {*} context 
   * @param {*} logger 
   */
  constructor(context, logger) {
    __log = new Logger("WM", logger)

    this.__context = context

    this.source2scope = new WeakMap()
    this.tasks = {}
    this.iconsElements = []
    this.currentTaskUrl = '';
    this.changeAppInProgress = false;
    
    IpfsBridgeServer.addMessageListener(window.ipfs)
  }

  /**
   * 
   * @param {*} hash 
   * @param {*} brotli 
   */
  async fetchIPFS(hash, brotli = false) {
    __log.info("Fetching from IPFS:", hash)
    let content = await window.ipfs_fetch(hash, brotli) 
    return content
  }

  /**
   * 
   * @param {*} loc 
   */
  async fetchManifest(loc) {
    let manifest
    if (loc.startsWith('https://') || loc.startsWith('http://localhost')) {
      const req = {
        url: loc + MANIFEST_PATH,
        method: 'GET'
      }
      const res = await (new XMLHttpRequestPromise()).send(req)
      if (res.status !== 200)   {
        __log.info("[http manifest load] failed to load:", loc, "-- response:" + res)
        throw { error: 'FAILED_MANIFEST_LOAD', loc }
      }
      manifest = res.responseText

    } else if (loc.startsWith("/permastore2/") || loc.startsWith("/ipfs/") || loc.startsWith("/ens/")) {
      if (loc.startsWith("/permastore2/")) {
      
        const fms_url = {
          'development': 'https://fms.dev.zippie.com',
          'testing': 'https://fms.testing.zippie.com',
          'release': 'https://fms.zippie.com'
        }
        if (!window.permastore) {
           window.permastore = await System.import(/* webpackChunkName: '99permastore' */ '@zippie/zippie-utils/src/permastore.js') 
        }
        window.permastore.setUri(fms_url[runtime_mode])
        
        const res = await window.permastore.list(loc.slice('/permastore2/'.length))
        loc = '/ipfs/' + res[res.length - 1].split('.')[1] // get the most recent entry
      }
      if (loc.startsWith("/ens/")) {
        loc = await lookupENSPath(loc)
      }

      manifest = JSON.parse(
        (await this.fetchIPFS(loc + MANIFEST_PATH))
          .toString("utf8")
      )

    }

    if (!manifest.start_url) {
      throw { error: 'FAILED_MANIFEST_START_URL', loc }
    }
    return { manifest, loc }
  }

  /**
   * 
   * @param {*} loc 
   */
  waitForBoot(loc) {
    const obj = this

    // XXX - Should implement some kind of failure timeout condition.
    return new Promise(function (resolve, reject) {
      function callback(bootedLocation) {
        if (loc !== bootedLocation)
          return
        obj.events.removeListener(callback)
        resolve()
      }
      obj.events.addListener(WM_EVENT_BOOTED, callback)
    })
  }  


  /**
   * 
   * @param {*} eventName 
   * @param {*} callback 
   */
  on(eventName, callback) {
    return this.events.addListener(eventName, callback)
  }

  /**
   * 
   * @param {*} eventName 
   * @param {*} callback 
   */
  off(eventName, callback) {
    return this.events.removeEvent(eventName, callback)
  }
  
  /**
   * 
   * @param {*} loc 
   * @param {*} params 
   * @param {*} privileges 
   * @param {*} intendedParent 
   */
  async bootApp(loc, params, privileges = {}, intendedParent = document.body) {
    // task already exists
    if (this.tasks[loc]) {
      if (this.tasks[loc].state !== WM_EVENT_RUNNING) {
        await this.waitForBoot(loc)
      }

      return { loc, iframe: this.tasks[loc].iframe }
    }
    this.tasks[loc] = {
      state: WM_EVENT_PRE_BOOTING
    }
    const fetchedInfo = await this.fetchManifest(loc)
    const { loc: base_uri, manifest, manifest: { start_url } } = fetchedInfo
    const start_loc = base_uri + start_url
    const brotli = start_url.endsWith(".br")

    const env = utils.getKlaatuEnv()

    const isWhiteListed = whiteListApps[env].find(appName => appName === loc)
    
    const iframe = document.createElement("iframe")


    iframe.style.display = "none"
    // XXX do we need to allow popups?
    iframe.allowPaymentRequest = true
    if(loc.includes('localhost') || loc.includes('ngrok')) {
    
      iframe.src = start_loc
      iframe.name = loc
      iframe.allow = 'camera;microphone'

    } else if (base_uri.startsWith("https://")) {
      iframe.src = start_loc
      iframe.name = loc
      iframe.sandbox = "allow-scripts allow-downloads"

    } else if (isWhiteListed) {
      const urls = {
        development: 'https://mm.dev.zippie.com',
        testing: 'https://mm.testing.zippie.com',
        release: 'https://mm.zippie.com'
      }
      iframe.src = `${urls[runtime_mode]}/#${start_loc},${brotli}`
      iframe.name = loc
      iframe.allow = 'camera;microphone'
    } 
    else {
      iframe.sandbox = "allow-scripts allow-downloads"
      iframe.name = loc

      const loc2 = start_loc

      
      const tmpl = `<script>function seed(e){if(e.data.result&&e.data.result.transferables[0]){removeEventListener('message', seed);document.open();document.write(new TextDecoder().decode(e.data.result.transferables[0]));document.close()}};addEventListener('message', seed);parent.postMessage({'wm_ipfs_fetch':{cid:'${loc2}',brotli:${brotli},transferable:true},callback:'x'},'*')</script>`
      iframe.srcdoc = tmpl
      // newiframe.src = URL.createObjectURL(new Blob([content], {type: 'text/html'})) + '#'
    }

    this.tasks[loc] = {
      iframe,
      manifest,
      params,
      privileges,
      state: WM_EVENT_BOOTING,
      parent: intendedParent,
      root: fetchedInfo.loc
    }
    intendedParent.appendChild(iframe)

    this.source2scope.set(iframe.contentWindow, loc)

    await this.waitForBoot(loc)
    return {loc: loc, iframe: iframe}
  }

  restoreIdentity (identity) {
    const decoded = JSON.parse(window.atob(identity));
    Object.keys(decoded).map((key) => localStorage.setItem(key, decoded[key]))
  }

   zippieIdentity () {
    const zippieIdUri = ZIPPIE_ID_URI[runtime_mode];
    const KYCUri = KYC_URI[runtime_mode];
    const mycredentialsUri = MY_CREDENTIALS_URI[runtime_mode]
    const zippieIdKey = `ds-${zippieIdUri}-identity`;
    const kycKey = `ds-${KYCUri}-jambopay-identity`
    const mycredentialsPersonalInformationKey = `ds-${mycredentialsUri}-userInformation`
    const mycredentialsPasscodeKey = `ds-${mycredentialsUri}-passcodeOptions`

    const zippieId = localStorage.getItem(zippieIdKey)
    const kyc = localStorage.getItem(kycKey)
    const personalInformation =  localStorage.getItem(mycredentialsPersonalInformationKey)
    const passcodeOptions =  localStorage.getItem(mycredentialsPasscodeKey)

    return {
      [zippieIdKey]: zippieId, 
      [kycKey]: kyc, 
      [mycredentialsPersonalInformationKey]: personalInformation,
      [mycredentialsPasscodeKey]: passcodeOptions,
      hasIdentity: Boolean(zippieId && kyc)
    }
  }

  /**
   * 
   * @param {*} contenturl 
   */
  async setAsCurrentContent (contenturl, shouldKeepAlive) {
    if(this.currentTaskUrl && !shouldKeepAlive) {
      this.tasks[this.currentTaskUrl].iframe.remove()
      delete this.tasks[this.currentTaskUrl]
      this.currentTaskUrl = null;
   
    }  else if(this.currentTaskUrl && this.tasks[this.currentTaskUrl]) {
      const currentTask = this.tasks[this.currentTaskUrl]
      currentTask.iframe.style.display = "none"
    }

    this.currentTaskUrl = contenturl;

    const manifest = this.tasks[contenturl].manifest
    const hasSwitcher = this.tasks[contenturl].privileges.hasSwitcher
    document.title = manifest.short_name

    for (var i = 0; i < this.iconsElements.length; i++) {
      document.head.removeChild(this.iconsElements[i])
    }
    this.iconsElements = []

    this.tasks[contenturl].iframe.style.display = "flex"
    if (manifest.icons) {
        for (var i = 0; i < manifest.icons.length; i++) {
          const icon = manifest.icons[i]
          let el = document.createElement("link")
          el.rel = "icon"
          el.type = icon.type
          el.sizes = icon.sizes
          let ic
          if (contenturl.startsWith('https://') || contenturl.startsWith('http://localhost')) {
             ic = Buffer.from(await (await fetch(this.tasks[contenturl].root + "/" + icon.src)).arrayBuffer())
          } else {
              ic = await this.fetchIPFS(this.tasks[contenturl].root + "/" + icon.src)
          }
          el.href = "data:" + icon.type + ";base64," + ic.toString("base64")

          document.head.appendChild(el)
          this.iconsElements.push(el)

          el = document.createElement("link")
          el.rel = "apple-touch-icon"
          el.type = icon.type
          el.sizes = icon.sizes
          el.href = "data:" + icon.type + ";base64," + ic.toString("base64")

          document.head.appendChild(el)
          this.iconsElements.push(el)
        }
      }
      // Disabled for now 
    // this.tasks[switcherurl].iframe.style.display = hasSwitcher ? 'flex' : 'none';

  }

  /**
   * 
   * @param {*} vault 
   */
  install (vault) {
    let contentAlreadyThere = false
    if (document.getElementById('content') || window.klaatu_opener_scenario) {
      contentAlreadyThere = true
    }

    if (window.klaatu_embedded_scenario) {
      window.parent.postMessage({handshake: true}, '*')
      contentAlreadyThere = true
      document.body.removeChild(document.getElementById("spinner"))
    }    
    const first_content_rights = {}

    this.vault = vault
    vault.wm = this
    let contentParams = {}

    this.events = new EventEmitter()
    vault.addReceiver(this)
    
    

    if(window.location.hash.includes("enter-dk=")){
      const enter_dk = getHashValue('enter-dk')
        contentParams = {
        enter_dk
      }
      console.log('enter-dk: ', enter_dk)
      removeHashParam('enter-dk')
    }
    if (window.zippie_pwa) {
      document.getElementById("manifest-placeholder").href = window.boot0_a2hs
    } else {
      // XXX make this work for iOS
    }

      // Disabled for now 

    // this.bootApp(switcherurl, {}, {switcher: true}, document.body).then((res) => {
    //   __log.info("Boot of switcher result:", res)
    //   res.iframe.id = "switcher"
    // }).catch(e => {
    //   __log.error("Boot of switcher error:", e.stack)
    // })
    
    contenturl  = CONTENT_URI[runtime_mode]

    if (window.location.hash.startsWith("#a2hs=")) {
      const app = getHashValue('#a2hs')
     
        const  {hasIdentity } = this.zippieIdentity()
        const identity = getHashValue('identity');

        // if identity is set on the start url and the user nuked local storage restore it
        if(!hasIdentity && identity) {
          this.restoreIdentity(identity)
        }
        contenturl = app
        first_content_rights.a2hs = true
    }
    if (window.location.hash.startsWith("#i=")) {
      const hasValue = getHashValue("i");
      contentParams = {
        hash: hasValue,
      };
      contenturl = INVITE_URI[runtime_mode];
    }
    // XXX don't rely on this, it'll be replaced with a developer mode
    if (window.location.hash.startsWith("#dev-ask")) {
      contenturl = prompt("Content URL?", contenturl)
    }
    // set main app hash value so we can keep the app after refresh
    if (getHashValue('main-app')) {
      contenturl = getHashValue('main-app')
    }

    if (window.location.hash.startsWith('#dev-content=')) {
      contenturl = getHashValue('dev-content')
    }

    // Zippie Pay API (#pay = Payment Request API - Chrome/Andorid, #pay-fullscreen = window.open - Safari/iOS
    let isZippiePayButton = window.location.hash.startsWith('#pay') && !contentAlreadyThere
    if (isZippiePayButton) {
      const isFullscreen = window.location.hash.startsWith('#pay-fullscreen')
      const paymentDataEncoded = isFullscreen ? getHashValue("pay-fullscreen") : getHashValue("pay");
      const paymentDataDecoded = JSON.parse(window.atob(paymentDataEncoded))
      contentParams = {
        paymentData: paymentDataDecoded,
        type: 'payment_request',
        isFullscreen: isFullscreen
      };
      contenturl = PAY_URI[runtime_mode];
    }
    const savedLocalAccepted = localStorage.getItem("termsAndConditionAccepted") || window.klaatu_embedded_scenario
    const hasAcceptedTermsAndCondition = savedLocalAccepted  ? JSON.parse(savedLocalAccepted) : false ;

    if (!contentAlreadyThere) {
      if (!(hasAcceptedTermsAndCondition || isZippiePayButton)) {
         const termsAndConditionUri = TERMS_AND_CONDITION_URI[runtime_mode];
         this.bootApp(termsAndConditionUri, { isStandalone: window.location.hash.startsWith('#pay') }, {}, document.body)
              .then((res) => {
              __log.info("Boot terms and condition result:", res)
              res.iframe.id = "terms-and-conditions"
              document.body.removeChild(document.getElementById("spinner"))
              this.setAsCurrentContent(termsAndConditionUri)
              .catch((err) => {
                  __log.error(err)
              })

         }).catch(e => {
            __log.error("Boot terms and condition error:", e.stack)
         })
      }
      
      this.bootApp(contenturl, contentParams, first_content_rights, document.body)
        .then((res) => {
          __log.info("Boot content result:", res)
          res.iframe.id = "content"
          if (hasAcceptedTermsAndCondition || isZippiePayButton) {
            // now remove the spinner
            document.body.removeChild(document.getElementById("spinner"))
            this.setAsCurrentContent(contenturl)
              .catch((err) => {
                __log.error(err)
              })
          } else {
          }

        }).catch(e => {
          __log.error("Boot content error:", e.stack)
        })
    } else if (window.klaatu_opener_scenario) {
      let url = window.initialTaskURL
      this.tasks[url] = {
        iframe: undefined,
        manifest: {},
        params: {},
        privileges: {},
        state: WM_EVENT_RUNNING,
        parent: document.body,
        root: ''
      }      
      this.source2scope.set(window.opener, url)  
      window.opener.postMessage({ready: true}, '*')
      this.currentTaskUrl = url
    } else if (!window.klaatu_embedded_scenario) {
      let url = window.initialTaskURL ? window.initialTaskURL : 'initial'
      this.tasks[url] = {
        iframe: document.getElementById('content'),
        manifest: {},
        params: {},
        privileges: {},
        state: WM_EVENT_RUNNING,
        parent: document.body,
        root: ''
        }
      
      this.source2scope.set(this.tasks[url].iframe.contentWindow, url)  
      this.tasks[url].iframe.contentWindow.postMessage({klaatu_ready: true}, '*')
      this.currentTaskUrl = url
    }
    return
  } 

  /**
   * 
   * @param {*} data 
   */
  async wm_change_app(data) {

    if(!data) return;
    if(!data.data.wm_change_app) return;
    if(!data.data.wm_change_app.url) return;
    const contenturl = data.data.wm_change_app.url
    const contentRights = data.data.wm_change_app.contentRights || {}
    const params = data.data.wm_change_app.params || {}
    const shouldKeepAlive =  data.data.wm_change_app.shouldKeepAlive || (this.currentTaskUrl && this.currentTaskUrl.includes('home'));

    if(contenturl === this.currentTaskUrl || this.changeAppInProgress) {
      return;
    }
    this.changeAppInProgress = true
    return this.bootApp(contenturl, params, contentRights, document.body)
      .then((res) => {
        __log.info("Boot content result:", res)
        res.iframe.id = "content"
        res.iframe.style.display = "flex"

        this.setAsCurrentContent(contenturl, shouldKeepAlive)
        .then(() => {
          this.changeAppInProgress = false
        })
        .catch(e => {
          __log.error("Set as current error:", e)
          this.changeAppInProgress = false

        })
      }).catch(e => {
        __log.error("Boot of content error:", e)
        this.changeAppInProgress = false

      })
  }
/**
   * 
   * @param {*} preload 
   */
  async wm_warmup(data) {
    if(!data) return;
    if(!data.data.wm_warmup) return;
    if(!data.data.wm_warmup.url) return;
    const appToPreload = data.data.wm_warmup.url;
    __log.info("Preload:", appToPreload)

    if(this.tasks[appToPreload]) {
      return;
    }
    return this.bootApp(appToPreload, {}, {}, document.body)
    .then((res) => {
      __log.info("Boot content result:", res)
      res.iframe.id = "content"
    }).catch(e => {
      __log.error("Boot of content error:", e)
    })
  }

  /**
   * 
   * @param {*} loc 
   */
  async a2hs(loc) {
    __log.info("a2hs:", loc)
    const { manifest } = this.tasks[loc]

    __log.info("a2hsing:", manifest)

    // XXX hash this with a private secret so we can't see it server side
    const identityInformation = this.zippieIdentity()
    const identity = btoa(JSON.stringify(identityInformation))
    manifest.start_url = window.location.origin + '/#a2hs=' + loc + '&identity=' + identity
    manifest.scope = window.location.origin + '/'
    manifest.zippie_loc = loc

    for (var i = 0; i < manifest.icons.length; i++) {
      const icon = manifest.icons[i]
      const data = await this.fetchIPFS(this.tasks[loc].root + '/' + icon.src)
      manifest.icons[i].src = 'data:' + icon.type + ';base64,' + data.toString('base64')
    }

    localStorage.setItem("a2hs-" + loc, btoa(JSON.stringify(manifest)))

    window.location.hash = "#a2hs=" + loc
    location.reload()
  } 

  /**
   * 
   * @param {*} event 
   */
  async wm_a2hs(event) {
    const loc = this.wm.source2scope.get(event.source)
    __log.info("Got a2hs message:", event)

    if (!this.wm.tasks[loc]) {
       throw { error: 'UNKNOWN_TASK' }
    }

    if (!this.wm.tasks[loc].privileges.a2hs) {
      await this.wm.a2hs(loc)
      return { ok: true }
    } else {
      return { error: "already a2hs mode" }
    }
  }

  /**
   * 
   * @param {*} event 
   */
  async wm_ready(event) {
    const loc = this.wm.source2scope.get(event.source)

    if (!this.wm.tasks[loc]) {
      throw { error: 'UNKNOWN_TASK' }
    }

    if (!this.wm.tasks[loc] === WM_EVENT_BOOTING) {
      throw { error: 'TASK_SENT_MULTIPLE_READY' }
    }

    this.wm.tasks[loc].state = WM_EVENT_RUNNING
    this.wm.events.emitEvent(WM_EVENT_BOOTED, [ loc ])

    __log.info("App", loc, "booted.")
    return { ok: true }
  }

  /**
   * 
   * @param {*} event 
   */
  async wm_ipfs_fetch(event) {
    const loc = this.wm.source2scope.get(event.source)
    const { cid, brotli, transferable } = event.data.wm_ipfs_fetch

    if (!this.wm.tasks[loc]) {
      throw { error: 'UNKNOWN_TASK' }
    }

    const contents = await this.wm.fetchIPFS(cid, brotli)
    if (transferable) {
      return {
        ok: true,
        transferables: [contents.buffer]
      }
    } else {
      return {
        ok: true,
        contents: contents.toString("utf8")
      }
    }
  }

  /**
   * 
   * @param {*} event 
   */
  async wm_internal_show_switcher(event) {
    const loc = this.wm.source2scope.get(event.source)

    // XXX only switcher can initiate this
    if (!this.wm.tasks[loc]) {
      throw { error: 'UNKNOWN_TASK' }
    }

    const switcher = this.wm.tasks[loc].iframe
    switcher.style.cssText = "margin: 0; height: 100%; width: 100%;"

    return { ok: true }
  }

  /**
   * 
   * @param {*} event 
   */
  async wm_internal_hide_switcher(event) {
    const loc = this.wm.source2scope.get(event.source)

    // XXX only switcher can initiate this
    if (!this.wm.tasks[loc]) {
      throw { error: 'UNKNOWN_TASK' }
    }

    const switcher = this.wm.tasks[loc].iframe
    switcher.style.borderRadius = "100%"
    switcher.style.height = "78px"
    switcher.style.width = "72px"
    switcher.style.left = "50%"
    switcher.style.transform = "translate(-50%,0)"

    return { ok: true }
  }

  /**
   * 
   * @param {*} payload 
   */
  async wm_permit_popup(payload) {
    if(!payload.data.wm_permit_popup.url) return;
    const loc = this.source2scope.get(payload.source)
    const { url } = payload.data.wm_permit_popup
    const currentTask = this.tasks[loc]
    if(!currentTask) {
      console.error(`Service ${loc} not found`)
      return {
        error: true
      }
    }
    currentTask.popoverPermitted = currentTask.popoverPermitted ? { ...currentTask.popoverPermitted, [url]: true } : { [url]: true } ;
    return { ok: true }
  }

  /**
   * 
   * @param {*} payload 
   */
  async wm_deny_popup(payload) {
    if(!payload.data.wm_permit_popup.url) return;
    const loc = this.source2scope.get(payload.source)

    const { url } = payload.data.wm_permit_popup
    const currentTask = this.tasks[loc]
    if(!currentTask) {
      console.error(`Service ${loc} not found`)
      return {
        error: true
      }
    }
    currentTask.popoverPermitted = currentTask.popoverPermitted ? { ...currentTask.popoverPermitted, [url]: false } : { [url]: false } ;
    return { ok: true }
  }

  /**
   * 
   * @param {*} payload 
   */
  async wm_open_popup(payload) {
    if(!payload.data.wm_open_popup) return;
    const loc = this.source2scope.get(payload.source)

    const popOverServiceTask = this.tasks[loc];
    const currentTask = this.tasks[this.currentTaskUrl];
    
    if(!currentTask) {
      console.error(`Current task ${loc} not found`)
      return {
        error: true
      }
    }
    if(popOverServiceTask) {
      //check if popover is permitted and popover is hidden
      if(popOverServiceTask.iframe.style.display !== 'none') {
        __log.error('wm_open_popup:' + loc + 'already open')
        return ;
      }
      if(currentTask.popoverPermitted && currentTask.popoverPermitted[loc]) {
        popOverServiceTask.iframe.style.display = 'flex'
        popOverServiceTask.iframe.classList.add("popup")
        //dirty fix for now so we can open passcode over all content 
        if(loc.includes('mycredentials')) {
          popOverServiceTask.iframe.id= 'passcode'
        }

      } else {
        __log.error('wm_open_popup: pop over of ' + this.currentTaskUrl + ' by ' + loc + 'not permitted')
        return Promise.reject('wm_open_popup: pop over of ' + this.currentTaskUrl + ' by ' + loc + 'not permitted')
      }
    } else {
      __log.error('message source not found: ' + loc)
      return Promise.reject('message source not found: ' + loc)
    }
    return;
  }

  /**
   * 
   * @param {*} payload 
   */
  async wm_close_popup(payload) {
    const loc = this.source2scope.get(payload.source)
    if (this.tasks[loc]) {
      this.tasks[loc].iframe.style.display = 'none'
    } else {
      __log.error('wm_close_popup: service not found: ' + loc)
    }
    
    return;
  }


  // close terms and condition iframe when accepted
  async wm_terms_accepted() {
    localStorage.setItem("termsAndConditionAccepted", true);
    sendEvent('terms-accepted', { terms_accepted: true })

    // already in progress avoid bad styling
    if(this.tasks[contenturl]) {
      this.tasks[contenturl].iframe.id = "content"
    }
      this.setAsCurrentContent(contenturl)
      .catch((err) => {
        __log.error(err)
      })
  }

  async wm_terms_denied() {
    localStorage.setItem("termsAndConditionAccepted", false);
    // already in progress avoid bad styling
    window.location = 'https://www.zippie.com'
  }


  // send message to service worker to complete payment request
  async wm_payment_request_result(payload) {
    if(!payload.data.wm_payment_request_result) return;
    const result = payload.data.wm_payment_request_result
    const response = {
      methodName: `${window.location.origin}/pay`,
      details: result 
    };

    if (result.isFullscreen) {
      // Send event to opener window and close this window
      window.opener.postMessage(response, '*');
      window.close();
    } else {
      // Request Payment API (send message to service worker)
      navigator.serviceWorker.controller.postMessage(response);
    }
  }

  async wm_get_params() {
    const loc = this.wm.source2scope.get(event.source)
    
    if (!this.wm.tasks[loc]) {
      throw { error: 'UNKNOWN_TASK' }
    }
    console.log('[klaatu] Params request from ' + loc + '', this.wm.tasks[loc].params)
    return this.wm.tasks[loc].params
  }
  

  /**
   * 
   * @param {*} params 
   */
  async wm_get_url_params(payload) {
    if(!payload.data.wm_get_url_params) return;
    const params = payload.data.wm_get_url_params.params
    const results = params.reduce((acc, param) => {
        const value = getHashValue(param) || null;
        return {...acc, [param]: value}
    }, {})
    return results
  }

  /**
   * 
   * @param {*} param
   */
  async wm_remove_url_param(payload) {
    if(!payload.data.wm_remove_url_param) return;
    const param= payload.data.wm_remove_url_param.param
    if(getHashValue(param)) {
      removeHashParam(param)
      return true
    }
    __log.error(`param ${param} does not exits`)
    return true
  }


  async wm_get_environment() {
    return runtime_mode
  }

  async wm_lookup_ens(payload) {
    let {manifest, loc} = await this.fetchManifest(payload.data.wm_lookup_ens)
    
    this.fetchIPFS(loc + manifest.start_url, manifest.start_url.endsWith('.br'))
    if (manifest.download_assets) {
       console.log('[Preloading] ', manifest.download_assets)
       for (var i = 0; i < manifest.download_assets.length; i++) {
          if (manifest.download_assets[i].brotli_hash) {
             this.fetchIPFS(manifest.download_assets[i].brotli_hash, true)
          } else {
             this.fetchIPFS(manifest.download_assets[i].hash, false)
          }
       }
    }
       
    return true  
  }
  async wm_set_main_app(payload) {
    const app  = payload.data.wm_set_main_app.mainApp;

    if(getHashValue('main-app')) {
      removeHashParam('main-app');
    }
    const currentHash = window.location.hash
    window.location.hash = currentHash ?  + `${currentHash}&main-app=${app}`: `main-app=${app}`

    return true
  }

  async wm_share_link(payload) {
    const shareLink  = payload.data.wm_share_link.shareLink;
    window.location = shareLink
    return true
  }

  async wm_is_embedded(payload) {
    return {embedded: window.klaatu_embedded_scenario, localStorage: !!window.temporaryLocalStorage }
  }
  
  async wm_register_parent(payload) {
    if (payload.source.self !== window.parent) {
      throw new Error('Not parent') 
    }   
    this.tasks[payload.origin + '/'] = {
        iframe: undefined,
        manifest: {},
        params: {},
        privileges: {},
        state: WM_EVENT_RUNNING,
        parent: document.body,
        root: ''
    }      
    this.source2scope.set(window.parent, payload.origin + '/')  
    payload.source.postMessage({ready: true}, payload.origin)
    this.currentTaskUrl = payload.origin + '/'
    return true
  }
  
  async wm_collect_data_popup_wait(payload) {
    if (!window.noLocalStorageAccess) {
      return
    }
    if (window.temporaryLocalStorage) 
        return
        
     await (new Promise((resolve, reject) => {
       window.popup_promise = resolve
     }))
     return
  }

  async wm_save_data_popup_wait(payload) {
    if (!window.noLocalStorageAccess) {
      return
    }
     if (window.save_localstorage)
        return
        
     await (new Promise((resolve, reject) => {
       window.popup_promise = resolve
     }))
     return
  }

  
  async wm_set_localstorage(payload) {
    if (!window.noLocalStorageAccess) {
      return
    }
    if (payload.source.self == window.popup) {
      console.error('*** LOCAL STORAGE *** ', payload.data)
      window.temporaryLocalStorage = payload.data.wm_set_localstorage
      window.popup.close()
      window.popup = undefined 
      if (window.popup_promise) { 
         window.popup_promise()
         window.popup_promise = undefined
      }
    } else {
      throw new Error('Not popup')
    }
    return
  } 

  async wm_get_localstorage(payload) {
    if (!window.noLocalStorageAccess) {
      return
    }
    if (payload.source.self == window.popup) {
      console.error('*** BEING ASKED FOR LOCAL STORAGE ***', payload.data)
      if (window.popup_promise) { 
         window.popup_promise()
         window.popup_promise = undefined
      }
    } else {
      throw new Error('Not popup')
    }
    return
  } 

  async wm_save_localstorage(payload) {
    if (!window.noLocalStorageAccess) {
      return
    }
    if (payload.source.self == window.parent) {
      console.error('*** BEING ASKED FOR LOCAL STORAGE ***', payload.data)
      window.save_localstorage = true
      if (window.popup_promise) { 
         window.popup_promise()
         window.popup_promise = undefined
      }
    } else {
      throw new Error('Not popup')
    }
    return
  } 

  async wm_logout(payload) {
    if (window.popup) {
       window.popup.postMessage({'wm_set_localstorage':{}}, window.origin)
       window.popup.close()
    } else {
       localStorage.clear()
    }
  }
  async wm_save_into_popup(payload) {
     if (!window.noLocalStorageAccess)
        return

     if (window.popup) {
       let response = {}
       let keys = Object.keys(window.temporaryLocalStorage)
       for (let i = 0; i < keys.length; i++) {
         response[keys[i]] = window.temporaryLocalStorage[keys[i]]
       }
       window.popup.postMessage({'wm_set_localstorage':response}, window.origin)
       window.popup.close()
     } else {
       throw new Error('No popup')
     }
  }

  async wm_close_save_popup(payload) {
    if (!window.noLocalStorageAccess) {
      return
    }
    if (window.popup) {
       window.save_localstorage = false
       window.popup.close()
     } else {
       throw new Error('No popup')
     }
  }
  
  dispatchTo (context, event) {
    let req = event.data

    if (context.mode === 'root') {
      if ('wm_roll' in req) return this.wm_roll
      if ('wm_ready' in req) return this.wm_ready
      if ('wm_a2hs' in req) return this.wm_a2hs
      if ('wm_ipfs_fetch' in req) return this.wm_ipfs_fetch
      if ('wm_internal_show_switcher' in req) return this.wm_internal_show_switcher
      if ('wm_internal_hide_switcher' in req) return this.wm_internal_hide_switcher
      if ('wm_change_app' in req) return this.wm_change_app.bind(this)
      if ('wm_permit_popup' in req) return this.wm_permit_popup.bind(this)
      if ('wm_deny_popup' in req) return this.wm_deny_popup.bind(this)
      if ('wm_get_params' in req) return this.wm_get_params
      if ('wm_get_url_params' in req) return this.wm_get_url_params
      if ('wm_remove_url_param' in req) return this.wm_remove_url_param
      if ('wm_open_popup' in req) return this.wm_open_popup.bind(this)
      if ('wm_close_popup' in req) return this.wm_close_popup.bind(this)
      if ('wm_terms_accepted' in req) return this.wm_terms_accepted.bind(this)
      if ('wm_terms_denied' in req) return this.wm_terms_denied.bind(this)
      if ('wm_warmup' in req) return this.wm_warmup.bind(this)
      if ('wm_get_environment' in req) return this.wm_get_environment
      if ('wm_payment_request_result' in req) return this.wm_payment_request_result.bind(this)
      if ('wm_share_link' in req) return this.wm_share_link.bind(this)
      if ('wm_lookup_ens' in req) return this.wm_lookup_ens.bind(this)
      if ('wm_set_main_app' in req) return this.wm_set_main_app.bind(this)
      if ('wm_is_embedded' in req) return this.wm_is_embedded.bind(this)
      if ('wm_register_parent' in req) return this.wm_register_parent.bind(this)
      if ('wm_collect_data_popup_wait' in req) return this.wm_collect_data_popup_wait.bind(this)
      if ('wm_set_localstorage' in req) return this.wm_set_localstorage.bind(this)
      if ('wm_get_localstorage' in req) return this.wm_get_localstorage.bind(this)
      if ('wm_save_into_popup' in req) return this.wm_save_into_popup.bind(this)      
      if ('wm_close_save_popup' in req) return this.wm_close_save_popup.bind(this)
      if ('wm_save_data_popup_wait' in req) return this.wm_save_data_popup_wait.bind(this)
      if ('wm_logout' in req) return this.wm_logout.bind(this)
     }

    return null
  }
  
}
